import { CircularProgress, Switch } from '@mui/material';
import Box from '@mui/material/Box';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormGroup from '@mui/material/FormGroup';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import PropTypes from 'prop-types';
import React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import ApiClient from '../../api-client/ApiClient';

export default class ExchangeBalance extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      exchanges: [],
      assets: [],
      balance: null,
      sumByAssets: null,
      sumInTRY: null,
      sumInUSDT: null,
      showFreeAssets: true,
      isLoading: false,
    };
  }

  componentDidMount() {
    this.fetchBalance();
    this.refreshDataTimer = setInterval(() => this.fetchBalance(), 60000);
  }

  componentWillUnmount() {
    clearInterval(this.refreshDataTimer);
  }

  get showFreeAssetsLabel() {
    const { showFreeAssets } = this.state;
    return showFreeAssets ? 'Kullanılabilir Bakiye' : 'Tüm Bakiye';
  }

  fetchBalance() {
    const { exchanges, assets } = this.props;

    this.setState({ isLoading: true });
    ApiClient.getExchangeBalance(exchanges.map((e) => e.id), assets.map((a) => a.id))
      .then((response) => {
        this.setState({
          exchanges: response.data.exchanges,
          assets: response.data.assets,
          balance: response.data.balance,
          sumByAssets: response.data.sum_by_assets,
          sumInTRY: response.data.sum_in_TRY,
          sumInUSDT: response.data.sum_in_USDT,
        });
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => this.setState({ isLoading: false }));
  }

  render() {
    const {
      exchanges, assets, balance, sumByAssets, sumInTRY, sumInUSDT, showFreeAssets, isLoading,
    } = this.state;
    return (
      <Grid container rowSpacing={2} columnSpacing={2}>
        <Grid item xs={6} display="flex" justifyContent="flex-start">
          <Typography variant="h6" component="h6">Bakiye</Typography>
        </Grid>
        <Grid item xs={6} display="flex" justifyContent="flex-end">
          <FormGroup>
            <FormControlLabel
              control={<Switch checked={showFreeAssets} />}
              label={this.showFreeAssetsLabel}
              labelPlacement="start"
              onChange={(event, checked) => this.setState({ showFreeAssets: checked })}
            />
          </FormGroup>
        </Grid>
        <Grid item xs={12} display="flex">
          {sumInTRY && sumInUSDT && (
            <Grid container rowSpacing={1} columnSpacing={1}>
              <Grid item xs={12} display="flex">
                <Box sx={{ display: 'inherit', gap: 1 }}>
                  <Typography variant="subtitle1" component="h6" fontWeight="bold">Toplam (TRY bazında):</Typography>
                  <Typography variant="subtitle1" component="h6">
                    {new Intl.NumberFormat('tr-TR', { style: 'currency', currency: 'TRY', maximumFractionDigits: 2 }).format(sumInTRY[showFreeAssets ? 'free' : 'total'])}
                  </Typography>
                </Box>
              </Grid>
              <Grid item xs={12} display="flex">
                <Box sx={{ display: 'inherit', gap: 1 }}>
                  <Typography variant="subtitle1" component="h6" fontWeight="bold">Toplam (USDT bazında):</Typography>
                  <Typography variant="subtitle1" component="h6">
                    {new Intl.NumberFormat('tr-TR', { style: 'currency', currency: 'USD', maximumFractionDigits: 2 }).format(sumInUSDT[showFreeAssets ? 'free' : 'total'])}
                  </Typography>
                </Box>
              </Grid>
            </Grid>
          )}
        </Grid>
        <Grid item xs={12}>
          {balance && sumByAssets ? (
            <TableContainer component={Paper}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Borsa</TableCell>
                    {assets.map((asset) => (<TableCell>{asset.id}</TableCell>))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {exchanges.map((exchange) => (
                    <TableRow>
                      <TableCell>{exchange.name}</TableCell>
                      {assets.map((asset) => (
                        <TableCell>
                          {`${Number(balance[exchange.id][asset.id][showFreeAssets ? 'free' : 'total']).toFixed(4)} ${asset.id}`}
                        </TableCell>
                      ))}
                    </TableRow>
                  ))}
                  <TableRow>
                    <TableCell><strong>Toplam</strong></TableCell>
                    {assets.map((asset) => (
                      <TableCell>
                        <strong>{`${Number(sumByAssets[asset.id][showFreeAssets ? 'free' : 'total']).toFixed(4)} ${asset.id}`}</strong>
                      </TableCell>
                    ))}
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          ) : (isLoading && <CircularProgress />)}
        </Grid>
      </Grid>
    );
  }
}

ExchangeBalance.propTypes = {
  exchanges: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
    }),
  ),
  assets: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
    }),
  ),
};

ExchangeBalance.defaultProps = {
  exchanges: [],
  assets: [],
};
