import { Controller, useForm } from 'react-hook-form';
import React, { useState } from 'react';
import { Alert } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import Box from '@mui/material/Box';
import LoadingButton from '@mui/lab/LoadingButton';
import TextField from '@mui/material/TextField';
import ApiClient from '../../api-client/ApiClient';
import useApiToken from '../../hooks/use-api-token';

function LoginForm() {
  const {
    formState: { isSubmitting }, handleSubmit, control,
  } = useForm({
    defaultValues: {
      username: '',
      password: '',
    },
  });

  const navigate = useNavigate();
  const [alert, setAlert] = useState(null);
  const [, setApiToken] = useApiToken();

  const onSubmit = (data) => {
    setAlert(null);
    ApiClient.obtainAuthToken(data)
      .then((response) => {
        setApiToken(response.data.token);
        navigate('/');
      })
      .catch((error) => {
        console.error(error);
        if (error.response.status === 400) {
          setAlert('Yanlış kullanıcı adı veya şifre girildi.');
        }
      });
  };

  return (
    <>
      {alert && <Box sx={{ mb: 2 }}><Alert severity="error">{alert}</Alert></Box>}
      <Box sx={{ mb: 2 }}>
        <Controller
          name="username"
          control={control}
          rules={{ required: 'Zorunlu alan' }}
          render={({ field, fieldState: { error } }) => (
            <TextField
              {...field}
              error={!!error}
              helperText={error && error.message}
              label="Kullanıcı Adı"
              variant="standard"
              fullWidth
            />
          )}
        />
      </Box>
      <Box sx={{ mb: 2 }}>
        <Controller
          name="password"
          control={control}
          rules={{ required: 'Zorunlu alan' }}
          render={({ field, fieldState: { error } }) => (
            <TextField
              {...field}
              error={!!error}
              helperText={error && error.message}
              label="Şifre"
              variant="standard"
              type="password"
              fullWidth
            />
          )}
        />
      </Box>
      <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
        <LoadingButton
          onClick={handleSubmit(onSubmit)}
          loading={isSubmitting}
          variant="contained"
          style={{ textTransform: 'none' }}
        >
          GİRİŞ YAP
        </LoadingButton>
      </Box>
    </>
  );
}

export default LoginForm;
