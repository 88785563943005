import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import PropTypes from 'prop-types';
import React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import ApiClient from '../../api-client/ApiClient';

export default class ExchangeArbitrageBotInsufficientBalanceEvents extends React.Component {
  constructor(props) {
    super(props);
    this.state = { events: [] };
  }

  componentDidMount() {
    this.fetchOrders();
    const { refreshData } = this.props;
    if (refreshData) {
      this.refreshDataTimer = setInterval(() => this.fetchOrders(), 5000);
    }
  }

  componentWillUnmount() {
    if (this.refreshDataTimer) {
      clearInterval(this.refreshDataTimer);
    }
  }

  fetchOrders() {
    const { botId } = this.props;
    ApiClient.getExchangeArbitrageBotInsufficientBalanceEvents(botId, 10, 0)
      .then((response) => {
        this.setState({ events: response.data.results });
      });
  }

  render() {
    const { events } = this.state;
    return (
      <Grid container rowSpacing={1}>
        <Grid item xs={12}>
          <Typography variant="h6" component="h6">Yetersiz Bakiye</Typography>
        </Grid>
        {events.length === 0 ? (
          <Grid item xs={12}>
            <Typography variant="body1" component="div">Yetersiz bakiye durumu oluşmadı.</Typography>
          </Grid>
        ) : (
          <Grid item xs={12}>
            <TableContainer component={Paper}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Tarih</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {events
                    .map((event) => (
                      <TableRow>
                        <TableCell>
                          {new Date(Date.parse(event.created)).toLocaleString()}
                        </TableCell>
                      </TableRow>
                    ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
        )}
      </Grid>
    );
  }
}

ExchangeArbitrageBotInsufficientBalanceEvents.propTypes = {
  botId: PropTypes.number.isRequired,
  refreshData: PropTypes.bool.isRequired,
};
