import React from 'react';
import CreateExchangeArbitrageBotForm from '../../../components/exchange-arbitrage-bot/CreateExchangeArbitrageBotForm';

function CreateExchangeArbitrageBotPage() {
  return (
    <CreateExchangeArbitrageBotForm />
  );
}

export default CreateExchangeArbitrageBotPage;
