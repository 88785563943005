import React, { useEffect, useState } from 'react';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import { useParams } from 'react-router-dom';
import ApiClient from '../../../api-client/ApiClient';
import ExchangeArbitrageBotInsufficientBalanceEvents from '../../../components/exchange-arbitrage-bot/ExchangeArbitrageBotInsufficientBalanceEvents';
import ExchangeArbitrageBotOpportunities from '../../../components/exchange-arbitrage-bot/ExchangeArbitrageBotOpportunities';
import ExchangeArbitrageBotOrders from '../../../components/exchange-arbitrage-bot/ExchangeArbitrageBotOrders';
import ExchangeArbitrageBotTable from '../../../components/exchange-arbitrage-bot/ExchangeArbitrageBotTable';

function ExchangeArbitrageBotDetailPage() {
  const { botId } = useParams();
  const [bot, setBot] = useState(null);

  const fetchBotDetails = () => {
    ApiClient.getExchangeArbitrageBot(botId)
      .then((response) => {
        setBot(response.data);
      })
      .catch((error) => {
        console.error(error);
      });
  };

  useEffect(() => {
    fetchBotDetails();
    const refreshDataTimer = setInterval(() => fetchBotDetails(), 5000);
    return () => { clearInterval(refreshDataTimer); };
  }, []);

  // const isBotActive = bot && bot.status === 'running';
  const isLiveMode = bot && !bot.dry_run;

  if (bot) {
    return (
      <Grid container rowSpacing={3} columnSpacing={3}>
        <Grid item xs={12}>
          <ExchangeArbitrageBotTable
            bots={[bot]}
            actions={{
              goToDetails: false,
              stop: {
                onSuccess: fetchBotDetails,
              },
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <Divider variant="middle" />
        </Grid>
        {isLiveMode ? (
          <>
            <Grid item xs={12} md={8}>
              <ExchangeArbitrageBotOpportunities botId={bot.id} refreshData={false} />
            </Grid>
            <Grid display={{ xs: 'block', md: 'none' }} item xs={12}>
              <Divider variant="middle" />
            </Grid>
            <Grid item xs={12} md={4}>
              <ExchangeArbitrageBotInsufficientBalanceEvents
                botId={bot.id}
                refreshData={false}
              />
            </Grid>
            <Grid item xs={12}>
              <Divider variant="middle" />
            </Grid>
            <Grid item xs={12}>
              <ExchangeArbitrageBotOrders botId={bot.id} refreshData={false} />
            </Grid>
          </>
        ) : (
          <Grid item xs={12}>
            <ExchangeArbitrageBotOpportunities botId={bot.id} refreshData={false} />
          </Grid>
        )}
      </Grid>
    );
  }
  return <div />;
}

export default ExchangeArbitrageBotDetailPage;
