import PropTypes from 'prop-types';
import React from 'react';

import {
  Bar, BarChart, CartesianGrid, ResponsiveContainer, Tooltip, XAxis, YAxis,
} from 'recharts';
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';

import ApiClient from '../../api-client/ApiClient';

export default class ProfitLossGraph extends React.Component {
  constructor(props) {
    super(props);
    this.state = { chartData: [], totalProfit: 0.0, isLoading: false };
  }

  componentDidMount() {
    this.fetchData();
  }

  // eslint-disable-next-line no-unused-vars
  componentDidUpdate(prevProps, prevState, snapshot) {
    const { market, startDate, endDate } = this.props;
    if (
      prevProps.market !== market
      || prevProps.startDate !== startDate
      || prevProps.endDate !== endDate
    ) {
      this.fetchData();
    }
  }

  fetchData() {
    this.setState({ isLoading: true });
    const { market, startDate, endDate } = this.props;
    ApiClient.getExchangeArbitrageAggregatedProfit(
      market.id,
      startDate.toISOString(),
      endDate.toISOString(),
    )
      .then((response) => {
        const chartData = response.data.intervals.map((interval) => ({
          dateLabel: new Date(interval.date).toLocaleString(navigator.language, {
            month: 'short',
            day: 'numeric',
            hour: 'numeric',
            minute: 'numeric',
          }),
          profit: interval.profit,
        }));
        this.setState({ chartData, totalProfit: response.data.total_profit });
      })
      .finally(() => {
        this.setState({ isLoading: false });
      });
  }

  render() {
    const { chartData, totalProfit, isLoading } = this.state;
    const { market } = this.props;
    return isLoading ? (
      <CircularProgress />
    ) : (
      <Box mt={2}>
        <Grid container rowSpacing={2}>
          <Grid item xs={12}>
            <Typography variant="h6">
              {`Toplam Kar/Zarar: ${totalProfit.toFixed(2)} ${market.quote_asset.id}`}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <ResponsiveContainer width="100%" height={400}>
              <BarChart
                width={500}
                height={400}
                data={chartData}
                margin={{
                  top: 20,
                  right: 20,
                  left: 20,
                  bottom: 20,
                }}
              >
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="dateLabel" angle={-60} textAnchor="end" height={125} />
                <YAxis />
                <Tooltip />
                <Bar dataKey="profit" fill="#4891d9" />
              </BarChart>
            </ResponsiveContainer>
          </Grid>
        </Grid>
      </Box>
    );
  }
}

ProfitLossGraph.propTypes = {
  market: PropTypes.shape({
    id: PropTypes.string.isRequired,
    base_asset: PropTypes.shape({
      id: PropTypes.string.isRequired,
    }),
    quote_asset: PropTypes.shape({
      id: PropTypes.string.isRequired,
    }),
  }).isRequired,
  startDate: PropTypes.instanceOf(Date).isRequired,
  endDate: PropTypes.instanceOf(Date).isRequired,
};
