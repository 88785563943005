import Grid from '@mui/material/Grid';
import PropTypes from 'prop-types';
import React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import ApiClient from '../../api-client/ApiClient';
import PaginatedTable from '../common/PaginatedTable';

export default class ExchangeArbitrageBotOpportunities extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      opportunities: [], page: 0, rowsPerPage: 10, count: 0,
    };
  }

  componentDidMount() {
    this.fetchOpportunities(0, 10);
    const { refreshData } = this.props;
    if (refreshData) {
      this.refreshDataTimer = setInterval(() => this.fetchOpportunities(), 5000);
    }
  }

  componentWillUnmount() {
    if (this.refreshDataTimer) {
      clearInterval(this.refreshDataTimer);
    }
  }

  get emptyRows() {
    const { opportunities, page, rowsPerPage } = this.state;
    return page > 0 ? rowsPerPage - opportunities.length : 0;
  }

  setPage = (page) => {
    const { rowsPerPage } = this.state;
    this.fetchOpportunities(page, rowsPerPage);
    this.setState({ page });
  };

  setRowsPerPage = (rowsPerPage) => {
    const { page } = this.state;
    this.fetchOpportunities(page, rowsPerPage);
    this.setState({ rowsPerPage });
  };

  fetchOpportunities(page, rowsPerPage) {
    const { botId } = this.props;
    ApiClient.getExchangeArbitrageBotOpportunities(botId, rowsPerPage, rowsPerPage * page)
      .then((response) => {
        this.setState({ opportunities: response.data.results, count: response.data.count });
      });
  }

  render() {
    const {
      opportunities, page, rowsPerPage, count,
    } = this.state;
    return (
      <Grid container rowSpacing={1}>
        <Grid item xs={12}>
          <Typography variant="h6" component="h6">Fırsatlar</Typography>
        </Grid>
        {count === 0 ? (
          <Grid item xs={12}>
            <Typography variant="body1" component="div">Fırsat bulunamadı.</Typography>
          </Grid>
        ) : (
          <Grid item xs={12}>
            <PaginatedTable
              count={count}
              page={page}
              setPage={this.setPage}
              rowsPerPage={rowsPerPage}
              setRowsPerPage={this.setRowsPerPage}
            >
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Tarih</TableCell>
                    <TableCell>Alış Borsası</TableCell>
                    <TableCell>Satış Borsası</TableCell>
                    <TableCell>Max. Adet</TableCell>
                    <TableCell>Max. Kâr</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {opportunities
                    .map((opportunity) => (
                      <TableRow>
                        <TableCell>
                          {new Date(Date.parse(opportunity.created)).toLocaleString()}
                        </TableCell>
                        <TableCell>{opportunity.buy_exchange.name}</TableCell>
                        <TableCell>{opportunity.sell_exchange.name}</TableCell>
                        <TableCell>
                          {`${parseFloat(opportunity.max_quantity)} ${opportunity.market.base_asset.id}`}
                        </TableCell>
                        <TableCell>
                          {`${parseFloat(opportunity.max_profit)} ${opportunity.market.quote_asset.id}`}
                        </TableCell>
                      </TableRow>
                    ))}
                  {this.emptyRows > 0 && (
                  <TableRow style={{ height: 53 * this.emptyRows }}>
                    <TableCell colSpan={5} />
                  </TableRow>
                  )}
                </TableBody>
              </Table>
            </PaginatedTable>
          </Grid>
        )}
      </Grid>
    );
  }
}

ExchangeArbitrageBotOpportunities.propTypes = {
  botId: PropTypes.number.isRequired,
  refreshData: PropTypes.bool.isRequired,
};
