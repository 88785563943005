import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormGroup from '@mui/material/FormGroup';
import Grid from '@mui/material/Grid';
import PropTypes from 'prop-types';
import React from 'react';
import Switch from '@mui/material/Switch';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import ApiClient from '../../api-client/ApiClient';
import PaginatedTable from '../common/PaginatedTable';

export default class ExchangeArbitrageBotOrders extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      orders: [], hideFailed: true, page: 0, rowsPerPage: 10, count: 0,
    };
  }

  componentDidMount() {
    this.fetchOrders(true, 0, 10);
    const { refreshData } = this.props;
    if (refreshData) {
      this.refreshDataTimer = setInterval(() => {
        const { hideFailed, page, rowsPerPage } = this.state;
        this.fetchOrders(hideFailed, page, rowsPerPage);
      }, 5000);
    }
  }

  componentWillUnmount() {
    if (this.refreshDataTimer) {
      clearInterval(this.refreshDataTimer);
    }
  }

  onHideFailedChange = (event, checked) => {
    const { rowsPerPage } = this.state;
    this.fetchOrders(checked, 0, rowsPerPage);
    this.setState({ hideFailed: checked, page: 0 });
  };

  get emptyRows() {
    const { orders, page, rowsPerPage } = this.state;
    return page > 0 ? rowsPerPage - orders.length : 0;
  }

  setPage = (page) => {
    const { hideFailed, rowsPerPage } = this.state;
    this.fetchOrders(hideFailed, page, rowsPerPage);
    this.setState({ page });
  };

  setRowsPerPage = (rowsPerPage) => {
    const { hideFailed, page } = this.state;
    this.fetchOrders(hideFailed, page, rowsPerPage);
    this.setState({ rowsPerPage });
  };

  fetchOrders(hideFailed, page, rowsPerPage) {
    const { botId } = this.props;
    ApiClient.getExchangeArbitrageBotOrders(botId, hideFailed, rowsPerPage, page * rowsPerPage)
      .then((response) => {
        this.setState({ orders: response.data.results, count: response.data.count });
      });
  }

  render() {
    const {
      orders, hideFailed, page, rowsPerPage, count,
    } = this.state;
    return (
      <Grid container rowSpacing={1}>
        <Grid item xs={6} display="flex" justifyContent="flex-start">
          <Typography variant="h6" component="h6">Emirler</Typography>
        </Grid>
        <Grid item xs={6} display="flex" justifyContent="flex-end">
          <FormGroup>
            <FormControlLabel
              control={<Switch checked={hideFailed} />}
              label="Başarısız emirleri gizle"
              labelPlacement="start"
              onChange={this.onHideFailedChange}
            />
          </FormGroup>
        </Grid>
        {count === 0 ? (
          <Grid item xs={12}>
            <Typography variant="body1" component="div">Emir bulunamadı.</Typography>
          </Grid>
        ) : (
          <Grid item xs={12}>
            <PaginatedTable
              count={count}
              page={page}
              setPage={this.setPage}
              rowsPerPage={rowsPerPage}
              setRowsPerPage={this.setRowsPerPage}
            >
              <Table stickyHeader>
                <TableHead>
                  <TableRow>
                    <TableCell>Tarih</TableCell>
                    <TableCell>Alış Borsası</TableCell>
                    <TableCell>Satış Borsası</TableCell>
                    <TableCell>Tahmini Kâr</TableCell>
                    <TableCell>Alış Adedi</TableCell>
                    <TableCell>Satış Adedi</TableCell>
                    <TableCell>Alış Fiyatı</TableCell>
                    <TableCell>Satış Fiyatı</TableCell>
                    <TableCell>Alış Emri</TableCell>
                    <TableCell>Satış Emri</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {orders
                    .map((order) => (
                      <TableRow>
                        <TableCell>
                          {new Date(Date.parse(order.created)).toLocaleString()}
                        </TableCell>
                        <TableCell>{order.buy_exchange.name}</TableCell>
                        <TableCell>{order.sell_exchange.name}</TableCell>
                        <TableCell>
                          {`${parseFloat(order.estimated_profit)} ${order.market.quote_asset.id}`}
                        </TableCell>
                        <TableCell>
                          {`${parseFloat(order.buy_quantity)} ${order.market.base_asset.id}`}
                        </TableCell>
                        <TableCell>
                          {`${parseFloat(order.sell_quantity)} ${order.market.base_asset.id}`}
                        </TableCell>
                        <TableCell>
                          {`${parseFloat(order.buy_price)} ${order.market.quote_asset.id}`}
                        </TableCell>
                        <TableCell>
                          {`${parseFloat(order.sell_price)} ${order.market.quote_asset.id}`}
                        </TableCell>
                        <TableCell>
                          {order.buy_order_error ? (<CloseIcon />) : (<CheckIcon />)}
                        </TableCell>
                        <TableCell>
                          {order.buy_order_error || order.sell_order_error
                            ? (<CloseIcon />) : (<CheckIcon />)}
                        </TableCell>
                      </TableRow>
                    ))}
                  {this.emptyRows > 0 && (
                    <TableRow style={{ height: 53 * this.emptyRows }}>
                      <TableCell colSpan={5} />
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </PaginatedTable>
          </Grid>
        )}
      </Grid>
    );
  }
}

ExchangeArbitrageBotOrders.propTypes = {
  botId: PropTypes.number.isRequired,
  refreshData: PropTypes.bool.isRequired,
};
