import { LOCAL_STORAGE_API_TOKEN_KEY } from '../constants';

function useApiToken() {
  const apiToken = localStorage.getItem(LOCAL_STORAGE_API_TOKEN_KEY);
  const setApiToken = (newApiToken) => {
    localStorage.setItem(LOCAL_STORAGE_API_TOKEN_KEY, newApiToken);
  };
  return [apiToken, setApiToken];
}

export default useApiToken;
