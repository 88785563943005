import { NavLink, Outlet } from 'react-router-dom';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import React from 'react';
import Toolbar from '@mui/material/Toolbar';

function Root() {
  return (
    <>
      <AppBar position="static">
        <Container>
          <Toolbar disableGutters>
            <Box sx={{ flexGrow: 1, display: 'flex' }}>
              <Button
                key="home"
                sx={{
                  my: 1, color: 'white', display: 'block', textTransform: 'none',
                }}
                component={NavLink}
                to="/"
              >
                ANA SAYFA
              </Button>
              <Button
                key="exchange-arbitrage-bots"
                sx={{
                  my: 1, color: 'white', display: 'block', textTransform: 'none',
                }}
                component={NavLink}
                to="/bot/exchange-arbitrage-bot/summary"
              >
                ARBİTRAJ BOTLARI
              </Button>
              <Button
                key="profit-and-loss"
                sx={{
                  my: 1, color: 'white', display: 'block', textTransform: 'none',

                }}
                component={NavLink}
                to="/profit-and-loss"
              >
                KAR/ZARAR
              </Button>
            </Box>
          </Toolbar>
        </Container>
      </AppBar>
      <Box sx={{ m: 3 }}>
        <Container>
          <Outlet />
        </Container>
      </Box>
    </>
  );
}

export default Root;
