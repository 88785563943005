import AddIcon from '@mui/icons-material/Add';
import Box from '@mui/material/Box';
import Fab from '@mui/material/Fab';
import { Link } from 'react-router-dom';
import React from 'react';
import ApiClient from '../../../api-client/ApiClient';
import ExchangeArbitrageBotTable from '../../../components/exchange-arbitrage-bot/ExchangeArbitrageBotTable';

export default class ExchangeArbitrageBotSummaryPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      bots: [],
    };
  }

  componentDidMount() {
    this.fetchBotList();
    this.refreshDataTimer = setInterval(() => this.fetchBotList(), 5000);
  }

  componentWillUnmount() {
    clearInterval(this.refreshDataTimer);
  }

  fetchBotList() {
    ApiClient.listExchangeArbitrageBots()
      .then((response) => {
        this.setState({ bots: response.data });
      })
      .catch((error) => {
        console.error(error);
      });
  }

  render() {
    const { bots } = this.state;
    return (
      <>
        <Box sx={{ mb: 3, display: 'flex', justifyContent: 'flex-end' }}>
          <Fab
            component={Link}
            to="/bot/exchange-arbitrage-bot/create"
            color="primary"
            size="small"
            variant="extended"
          >
            <AddIcon />
            Bot Oluştur
          </Fab>
        </Box>
        <ExchangeArbitrageBotTable
          bots={bots}
          actions={{
            goToDetails: true,
            stop: {
              onSuccess: () => {
                this.fetchBotList();
              },
            },
          }}
        />
      </>
    );
  }
}
