import {
  Navigate, RouterProvider, createBrowserRouter,
} from 'react-router-dom';
import PropTypes from 'prop-types';
import React from 'react';
import CreateExchangeArbitrageBotPage from './routes/bot/exchange-arbitrage-bot/CreateExchangeArbitrageBotPage';
import ExchangeArbitrageBotDetailPage from './routes/bot/exchange-arbitrage-bot/ExchangeArbitrageBotDetailPage';
import ExchangeArbitrageBotSummaryPage from './routes/bot/exchange-arbitrage-bot/ExchangeArbitrageBotSummaryPage';
import HomePage from './routes/home/HomePage';
import LoginPage from './routes/auth/LoginPage';
import ProfitAndLossPage from './routes/pnl/ProfitAndLossPage';
import Root from './routes/Root';
import useApiToken from './hooks/use-api-token';

function ProtectedPage({ children }) {
  const [apiToken] = useApiToken();
  if (!apiToken) {
    return <Navigate to="/log-in" replace />;
  }
  return children;
}
ProtectedPage.propTypes = {
  children: PropTypes.node.isRequired,
};

function App() {
  const router = createBrowserRouter([
    {
      path: '/log-in',
      element: <LoginPage />,
    },
    {
      path: '/',
      element: <ProtectedPage><Root /></ProtectedPage>,
      children: [
        { index: true, element: <HomePage /> },
        {
          path: 'bot/exchange-arbitrage-bot/summary',
          element: <ExchangeArbitrageBotSummaryPage />,
        },
        {
          path: 'bot/exchange-arbitrage-bot/:botId',
          element: <ExchangeArbitrageBotDetailPage />,
        },
        {
          path: 'bot/exchange-arbitrage-bot/create',
          element: <CreateExchangeArbitrageBotPage />,
        },
        {
          path: 'profit-and-loss',
          element: <ProfitAndLossPage />,
        },
      ],
    },
  ]);

  return (
    <RouterProvider router={router} />
  );
}

export default App;
