import React from 'react';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import LoginForm from '../../components/auth/LoginForm';

function LoginPage() {
  return (
    <Box sx={{ m: 3 }}>
      <Container maxWidth="xs">
        <LoginForm />
      </Container>
    </Box>
  );
}

export default LoginPage;
