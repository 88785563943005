import Button from '@mui/material/Button';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import InfoIcon from '@mui/icons-material/Info';
import { Link } from 'react-router-dom';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import Paper from '@mui/material/Paper';
import PropTypes from 'prop-types';
import React from 'react';
import StopIcon from '@mui/icons-material/Stop';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import ApiClient from '../../api-client/ApiClient';

const getReadableStatus = (bot) => {
  switch (bot.status) {
    case 'initializing':
      return 'Başlatılıyor';
    case 'running':
      return 'Çalışıyor';
    case 'stopping':
      return 'Durduruluyor';
    case 'failed':
      return 'Hata alındı';
    case 'stopped':
      return 'Durduruldu';
    default:
      return 'Bilinmiyor';
  }
};

const isStopBotButtonDisabled = (bot) => bot.status === 'stopping' || bot.status === 'failed' || bot.status === 'stopped';

export default class ExchangeArbitrageBotTable extends React.Component {
  constructor(props) {
    super(props);
    this.state = { stopActionInProgress: false };
  }

  onClickStopBot(bot) {
    const { actions } = this.props;
    this.setState({ stopActionInProgress: true });
    ApiClient.stopExchangeArbitrageBot(bot.id)
      .then((response) => {
        console.log(response);
        const { onSuccess } = actions.stop;
        if (onSuccess) {
          onSuccess();
        }
      })
      .catch((error) => {
        console.error(error);
        const { onFail } = actions.stop;
        if (onFail) {
          onFail();
        }
      })
      .finally(() => this.setState({ stopActionInProgress: false }));
  }

  get showBotDetailsAction() {
    const { actions } = this.props;
    return !!actions.goToDetails;
  }

  get showStopBotAction() {
    const { actions } = this.props;
    return !!actions.stop;
  }

  render() {
    const { bots } = this.props;
    const { stopActionInProgress } = this.state;
    return (
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Market</TableCell>
              <TableCell>Borsalar</TableCell>
              <TableCell>Durum</TableCell>
              <TableCell>Canlı Mod</TableCell>
              <TableCell>Emir Tipi</TableCell>
              <TableCell>Emir Başına Min. Adet</TableCell>
              <TableCell>Emir Başına Max. Adet</TableCell>
              <TableCell>1 Adette Min. Kâr</TableCell>
              <TableCell>Aksiyonlar</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {bots.map((bot) => (
              <TableRow key={bot.id}>
                <TableCell>{`${bot.market.base_asset.id} - ${bot.market.quote_asset.id}`}</TableCell>
                <TableCell>
                  <List>
                    {bot.exchanges.map((exchange) => (
                      <ListItem
                        key={exchange.id}
                        disableGutters
                      >
                        {exchange.name}
                      </ListItem>
                    ))}
                  </List>
                </TableCell>
                <TableCell>{getReadableStatus(bot)}</TableCell>
                <TableCell>{bot.dry_run ? (<CloseIcon />) : (<CheckIcon />)}</TableCell>
                <TableCell>{bot.order_type}</TableCell>
                <TableCell>{`${parseFloat(bot.min_quantity_per_trade)} ${bot.market.base_asset.id}`}</TableCell>
                <TableCell>{`${parseFloat(bot.max_quantity_per_trade)} ${bot.market.base_asset.id}`}</TableCell>
                <TableCell>{`${parseFloat(bot.min_profit_per_quantity)} ${bot.market.quote_asset.id}`}</TableCell>
                <TableCell>
                  <List>
                    {this.showBotDetailsAction
                      && (
                        <ListItem key="go-to-details" disableGutters>
                          <Button
                            component={Link}
                            to={`/bot/exchange-arbitrage-bot/${bot.id}`}
                            size="small"
                            variant="contained"
                            startIcon={<InfoIcon fontSize="small" />}
                          >
                            Detay
                          </Button>
                        </ListItem>
                      )}
                    {this.showStopBotAction
                      && (
                        <ListItem key="stop" disableGutters>
                          <Button
                            disabled={stopActionInProgress || isStopBotButtonDisabled(bot)}
                            onClick={() => this.onClickStopBot(bot)}
                            size="small"
                            variant="contained"
                            color="error"
                            startIcon={<StopIcon fontSize="small" />}
                          >
                            Durdur
                          </Button>
                        </ListItem>
                      )}
                  </List>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    );
  }
}

ExchangeArbitrageBotTable.propTypes = {
  bots: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      status: PropTypes.string.isRequired,
      dry_run: PropTypes.bool.isRequired,
      created: PropTypes.string.isRequired,
      modified: PropTypes.string.isRequired,
      market: PropTypes.shape({
        id: PropTypes.string.isRequired,
        base_asset: PropTypes.shape({
          id: PropTypes.string.isRequired,
        }),
        quote_asset: PropTypes.shape({
          id: PropTypes.string.isRequired,
        }),
      }),
      exchanges: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.string.isRequired,
          name: PropTypes.string.isRequired,
        }),
      ).isRequired,
      order_type: PropTypes.string.isRequired,
      min_quantity_per_trade: PropTypes.string.isRequired,
      max_quantity_per_trade: PropTypes.string.isRequired,
      min_profit_per_quantity: PropTypes.string.isRequired,
    }),
  ).isRequired,
  actions: PropTypes.shape({
    goToDetails: PropTypes.bool,
    stop: PropTypes.oneOfType([
      PropTypes.bool,
      PropTypes.shape({
        onSuccess: PropTypes.func,
        onFail: PropTypes.func,
      }),
    ]),
  }).isRequired,
};
