import React from 'react';

import Box from '@mui/material/Box';
import FormControl from '@mui/material/FormControl';
import Grid from '@mui/material/Grid';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import ApiClient from '../../api-client/ApiClient';
import ProfitLossGraph from '../../components/profit-and-loss/ProfitLossGraph';

const DEFAULT_MARKET = 'BTC_TRY';
const DEFAULT_DATE_RANGE = '1w';
const dateRangeOptions = [
  {
    id: '1w',
    readableName: 'Son 1 Hafta',
    dateRangeFn: () => {
      const endDate = new Date();
      const startDate = new Date(
        endDate.getFullYear(),
        endDate.getMonth(),
        endDate.getDate() - 7,
        endDate.getHours(),
        endDate.getMinutes(),
        endDate.getSeconds(),
        endDate.getMilliseconds(),
      );
      return { startDate, endDate };
    },
  },
  {
    id: '2w',
    readableName: 'Son 2 Hafta',
    dateRangeFn: () => {
      const endDate = new Date();
      const startDate = new Date(
        endDate.getFullYear(),
        endDate.getMonth(),
        endDate.getDate() - 14,
        endDate.getHours(),
        endDate.getMinutes(),
        endDate.getSeconds(),
        endDate.getMilliseconds(),
      );
      return { startDate, endDate };
    },
  },
  {
    id: '1m',
    readableName: 'Son 1 Ay',
    dateRangeFn: () => {
      const endDate = new Date();
      const startDate = new Date(
        endDate.getFullYear(),
        endDate.getMonth() - 1,
        endDate.getDate(),
        endDate.getHours(),
        endDate.getMinutes(),
        endDate.getSeconds(),
        endDate.getMilliseconds(),
      );
      return { startDate, endDate };
    },
  },
  {
    id: '3m',
    readableName: 'Son 3 Ay',
    dateRangeFn: () => {
      const endDate = new Date();
      const startDate = new Date(
        endDate.getFullYear(),
        endDate.getMonth() - 3,
        endDate.getDate(),
        endDate.getHours(),
        endDate.getMinutes(),
        endDate.getSeconds(),
        endDate.getMilliseconds(),
      );
      return { startDate, endDate };
    },
  },
  {
    id: '6m',
    readableName: 'Son 6 Ay',
    dateRangeFn: () => {
      const endDate = new Date();
      const startDate = new Date(
        endDate.getFullYear(),
        endDate.getMonth() - 6,
        endDate.getDate(),
        endDate.getHours(),
        endDate.getMinutes(),
        endDate.getSeconds(),
        endDate.getMilliseconds(),
      );
      return { startDate, endDate };
    },
  },
];

export default class ProfitAndLossPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedMarketId: DEFAULT_MARKET,
      selectedDateRangeId: DEFAULT_DATE_RANGE,
      availableMarkets: [],
    };
  }

  componentDidMount() {
    ApiClient.listMarkets().then((response) => {
      this.setState({ availableMarkets: response.data });
    });
  }

  onMarketChange(event) {
    this.setState({ selectedMarketId: event.target.value });
  }

  onDateRangeChange(event) {
    this.setState({ selectedDateRangeId: event.target.value });
  }

  get selectedMarket() {
    const { selectedMarketId, availableMarkets } = this.state;
    return availableMarkets.find((market) => market.id === selectedMarketId);
  }

  get selectedDateRange() {
    const { selectedDateRangeId } = this.state;
    return dateRangeOptions.find((dateRange) => dateRange.id === selectedDateRangeId);
  }

  render() {
    const { selectedMarketId, selectedDateRangeId, availableMarkets } = this.state;
    const { startDate, endDate } = this.selectedDateRange.dateRangeFn();
    return (
      <Box>
        <Grid container rowSpacing={2} columnSpacing={2}>
          <Grid item xs={6}>
            <FormControl fullWidth>
              <InputLabel>Market</InputLabel>
              <Select
                id="market"
                value={selectedMarketId}
                label="Market"
                onChange={(event) => this.onMarketChange(event)}
              >
                {availableMarkets.map((market) => (
                  <MenuItem
                    key={market.id}
                    value={market.id}
                  >
                    {`${market.base_asset.id} - ${market.quote_asset.id}`}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={6}>
            <FormControl fullWidth>
              <InputLabel>Tarih Aralığı</InputLabel>
              <Select
                id="date-range"
                value={selectedDateRangeId}
                label="Tarih Aralığı"
                onChange={(event) => this.onDateRangeChange(event)}
              >
                {dateRangeOptions.map((dateRange) => (
                  <MenuItem
                    key={dateRange.id}
                    value={dateRange.id}
                  >
                    {dateRange.readableName}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          {this.selectedMarket && this.selectedDateRange ? (
            <Grid item xs={12} display="block">
              <ProfitLossGraph
                market={availableMarkets.find((market) => market.id === selectedMarketId)}
                startDate={startDate}
                endDate={endDate}
              />
            </Grid>
          ) : (<div />)}
        </Grid>
      </Box>
    );
  }
}
