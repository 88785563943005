import { Controller, useForm, useWatch } from 'react-hook-form';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Box from '@mui/material/Box';
import Checkbox from '@mui/material/Checkbox';
import Divider from '@mui/material/Divider';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormGroup from '@mui/material/FormGroup';
import FormHelperText from '@mui/material/FormHelperText';
import FormLabel from '@mui/material/FormLabel';
import InputAdornment from '@mui/material/InputAdornment';
import InputLabel from '@mui/material/InputLabel';
import LoadingButton from '@mui/lab/LoadingButton';
import MenuItem from '@mui/material/MenuItem';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import Select from '@mui/material/Select';
import TextField from '@mui/material/TextField';
import ApiClient from '../../api-client/ApiClient';

function CreateExchangeArbitrageBotForm() {
  const navigate = useNavigate();

  const [availableMarkets, setAvailableMarkets] = useState([]);
  const [availableExchanges, setAvailableExchanges] = useState([]);
  const {
    formState: { isSubmitting }, resetField, handleSubmit, control,
  } = useForm({
    defaultValues: {
      market_id: '',
      exchange_ids: [],
      dry_run: false,
      order_type: '',
      min_quantity_per_trade: '',
      max_quantity_per_trade: '',
      min_profit_per_quantity: '',
    },
  });

  const selectedMarketId = useWatch({ control, name: 'market_id', defaultValue: '' });
  const selectedMarket = selectedMarketId !== '' && availableMarkets.find((market) => market.id === selectedMarketId);
  const baseAsset = selectedMarket && selectedMarket.base_asset.id;
  const quoteAsset = selectedMarket && selectedMarket.quote_asset.id;

  const fetchAvailableMarkets = () => {
    ApiClient.listMarkets()
      .then((response) => {
        setAvailableMarkets(response.data);
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const fetchAvailableExchanges = (marketId) => {
    ApiClient.listExchangesForMarket(marketId)
      .then((response) => {
        setAvailableExchanges(response.data);
        resetField('exchange_ids');
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const onValid = (data) => {
    ApiClient.createExchangeArbitrageBot(data)
      .then((response) => {
        console.log(response);
        navigate(`/bot/exchange-arbitrage-bot/${response.data.id}`);
      })
      .catch((error) => {
        console.error(error);
      });
  };
  const onError = (data) => {
    console.error(data);
  };

  useEffect(() => fetchAvailableMarkets(), []);

  return (
    <>
      <Box sx={{ mb: 2 }}>
        <FormControl fullWidth>
          <InputLabel>Market</InputLabel>
          <Controller
            name="market_id"
            control={control}
            render={({ field }) => (
              <Select
                {...field}
                onChange={(event) => {
                  field.onChange(event);
                  fetchAvailableExchanges(event.target.value);
                }}
                label="Market"
              >
                {availableMarkets.map((market) => (
                  <MenuItem
                    key={market.id}
                    value={market.id}
                  >
                    {`${market.base_asset.id} - ${market.quote_asset.id}`}
                  </MenuItem>
                ))}
              </Select>
            )}
          />
        </FormControl>
      </Box>
      {selectedMarketId !== ''
        && (
          <>
            <Divider />
            <Box sx={{ my: 2 }}>
              <FormControl fullWidth>
                <FormLabel>Borsalar</FormLabel>
                <FormGroup row>
                  <Controller
                    name="exchange_ids"
                    control={control}
                    rules={{
                      validate: {
                        minTwoExchange: (v) => v.length >= 2 || 'En az iki borsa seçiniz.',
                      },
                    }}
                    render={({ field, fieldState: { error } }) => (
                      <>
                        {availableExchanges.map((exchange) => (
                          <FormControlLabel
                            key={exchange.id}
                            label={exchange.name}
                            control={(
                              <Checkbox
                                name={exchange.id}
                                checked={field.value.includes(exchange.id)}
                                onChange={(event, checked) => {
                                  if (checked) {
                                    field.onChange([...field.value, event.target.name]);
                                  } else {
                                    field.onChange(
                                      field.value.filter((el) => el !== event.target.name),
                                    );
                                  }
                                }}
                              />
                            )}
                          />
                        ))}
                        {error && <FormHelperText error>{error.message}</FormHelperText>}
                      </>
                    )}
                  />
                </FormGroup>
              </FormControl>
            </Box>
            <Divider />
            <Box sx={{ my: 2 }}>
              <FormControl fullWidth>
                <FormGroup>
                  <FormControlLabel
                    label="Test Modu"
                    control={(
                      <Controller
                        name="dry_run"
                        control={control}
                        render={({ field }) => (
                          <Checkbox {...field} />
                        )}
                      />
                    )}
                  />
                </FormGroup>
              </FormControl>
            </Box>
            <Divider />
            <Box sx={{ mt: 2 }}>
              <FormControl>
                <FormLabel>Emir Tipi</FormLabel>
                <Controller
                  name="order_type"
                  control={control}
                  rules={{ required: 'Emir tipi seçiniz.' }}
                  render={({ field, fieldState: { error } }) => (
                    <>
                      <RadioGroup
                        {...field}
                        row
                      >
                        <FormControlLabel value="limit" control={<Radio />} label="Limit" />
                        <FormControlLabel value="market" control={<Radio />} label="Market" />
                      </RadioGroup>
                      {error && <FormHelperText error>{error.message}</FormHelperText>}
                    </>
                  )}
                />
              </FormControl>
            </Box>
            <Box sx={{ mt: 2 }}>
              <Controller
                name="min_quantity_per_trade"
                control={control}
                rules={{ required: 'Zorunlu alan' }}
                render={({ field, fieldState: { error } }) => (
                  <TextField
                    {...field}
                    error={!!error}
                    helperText={error && error.message}
                    label={`Emir Başına Min. ${baseAsset}`}
                    variant="standard"
                    type="number"
                    fullWidth
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">{baseAsset}</InputAdornment>
                      ),
                    }}
                  />
                )}
              />
            </Box>
            <Box sx={{ mt: 2 }}>
              <Controller
                name="max_quantity_per_trade"
                control={control}
                rules={{ required: 'Zorunlu alan' }}
                render={({ field, fieldState: { error } }) => (
                  <TextField
                    {...field}
                    error={!!error}
                    helperText={error && error.message}
                    label={`Emir Başına Max. ${baseAsset}`}
                    variant="standard"
                    type="number"
                    fullWidth
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">{baseAsset}</InputAdornment>
                      ),
                    }}
                  />
                )}
              />
            </Box>
            <Box sx={{ mt: 2 }}>
              <Controller
                name="min_profit_per_quantity"
                control={control}
                rules={{ required: 'Zorunlu alan' }}
                render={({ field, fieldState: { error } }) => (
                  <TextField
                    {...field}
                    error={!!error}
                    helperText={error && error.message}
                    label={`1 ${baseAsset} İçin Min. Kâr`}
                    variant="standard"
                    type="number"
                    fullWidth
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">{quoteAsset}</InputAdornment>
                      ),
                    }}
                  />
                )}
              />
            </Box>
            <Box sx={{ mt: 2, display: 'flex', justifyContent: 'flex-end' }}>
              <LoadingButton
                onClick={handleSubmit(onValid, onError)}
                loading={isSubmitting}
                variant="contained"
                style={{ textTransform: 'none' }}
              >
                BOT OLUŞTUR
              </LoadingButton>
            </Box>
          </>
        )}
    </>
  );
}

export default CreateExchangeArbitrageBotForm;
