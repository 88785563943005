import Paper from '@mui/material/Paper';
import PropTypes from 'prop-types';
import React from 'react';
import TableContainer from '@mui/material/TableContainer';
import TablePagination from '@mui/material/TablePagination';

export default function PaginatedTable(props) {
  const {
    count, page, setPage, rowsPerPage, setRowsPerPage, children,
  } = props;

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  return (
    <Paper>
      <TableContainer>
        {children}
      </TableContainer>
      <TablePagination
        component="div"
        rowsPerPageOptions={[10, 25, 100]}
        count={count}
        page={page}
        rowsPerPage={rowsPerPage}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
        labelDisplayedRows={(obj) => `${obj.from}–${obj.to} / ${obj.count}`}
        labelRowsPerPage="Satır sayısı:"
      />
    </Paper>
  );
}

PaginatedTable.propTypes = {
  count: PropTypes.number.isRequired,
  page: PropTypes.number.isRequired,
  setPage: PropTypes.func.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
  setRowsPerPage: PropTypes.func.isRequired,
  children: PropTypes.element.isRequired,
};
