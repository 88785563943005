import axios from 'axios';
import { LOCAL_STORAGE_API_TOKEN_KEY } from '../constants';

const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  timeout: 5000,
});

export default class ApiClient {
  static obtainAuthToken(data) {
    return axiosInstance.post('/api-token-auth/', data);
  }

  static listMarkets() {
    return axiosInstance.get(
      '/exchange/market',
      { headers: { Authorization: `Token ${localStorage.getItem(LOCAL_STORAGE_API_TOKEN_KEY)}` } },
    );
  }

  static listExchangesForMarket(marketId) {
    return axiosInstance.get(
      '/exchange/exchange',
      {
        params: { market_id: marketId },
        headers: { Authorization: `Token ${localStorage.getItem(LOCAL_STORAGE_API_TOKEN_KEY)}` },
      },
    );
  }

  static getExchangeBalance(exchangeIds, assetIds) {
    return axiosInstance.get(
      '/exchange/balance',
      {
        params: {
          exchange_ids: exchangeIds && exchangeIds.join(','),
          asset_ids: assetIds && assetIds.join(','),
        },
        headers: { Authorization: `Token ${localStorage.getItem(LOCAL_STORAGE_API_TOKEN_KEY)}` },
      },
    );
  }

  static listExchangeArbitrageBots() {
    return axiosInstance.get(
      '/bot/exchange-arbitrage-bot',
      { headers: { Authorization: `Token ${localStorage.getItem(LOCAL_STORAGE_API_TOKEN_KEY)}` } },
    );
  }

  static getExchangeArbitrageBot(botId) {
    return axiosInstance.get(
      `/bot/exchange-arbitrage-bot/${botId}`,
      { headers: { Authorization: `Token ${localStorage.getItem(LOCAL_STORAGE_API_TOKEN_KEY)}` } },
    );
  }

  static createExchangeArbitrageBot(data) {
    return axiosInstance.post(
      '/bot/exchange-arbitrage-bot',
      data,
      { headers: { Authorization: `Token ${localStorage.getItem(LOCAL_STORAGE_API_TOKEN_KEY)}` } },
    );
  }

  static stopExchangeArbitrageBot(botId) {
    return axiosInstance.delete(
      `/bot/exchange-arbitrage-bot/${botId}`,
      { headers: { Authorization: `Token ${localStorage.getItem(LOCAL_STORAGE_API_TOKEN_KEY)}` } },
    );
  }

  static getExchangeArbitrageBotInsufficientBalanceEvents(botId, limit = 10, offset = 0) {
    return axiosInstance.get(
      '/exchange-arbitrage/insufficient-balance-events',
      {
        params: { bot_id: botId, limit, offset },
        headers: { Authorization: `Token ${localStorage.getItem(LOCAL_STORAGE_API_TOKEN_KEY)}` },
      },
    );
  }

  static getExchangeArbitrageBotOpportunities(botId, limit = 10, offset = 0) {
    return axiosInstance.get(
      '/exchange-arbitrage/opportunities',
      {
        params: { bot_id: botId, limit, offset },
        headers: { Authorization: `Token ${localStorage.getItem(LOCAL_STORAGE_API_TOKEN_KEY)}` },
      },
    );
  }

  static getExchangeArbitrageBotOrders(botId, hideFailed, limit = 10, offset = 0) {
    return axiosInstance.get(
      '/exchange-arbitrage/orders',
      {
        params: {
          bot_id: botId, hide_failed: hideFailed, limit, offset,
        },
        headers: { Authorization: `Token ${localStorage.getItem(LOCAL_STORAGE_API_TOKEN_KEY)}` },
      },
    );
  }

  static getExchangeArbitrageAggregatedProfit(marketId, startDate, endDate) {
    return axiosInstance.get(
      '/exchange-arbitrage/aggregated-profit/',
      {
        params: { market_id: marketId, start_date: startDate, end_date: endDate },
        headers: { Authorization: `Token ${localStorage.getItem(LOCAL_STORAGE_API_TOKEN_KEY)}` },
      },
    );
  }
}
